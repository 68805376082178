







import { Component, Vue } from "vue-property-decorator";
import LoginForm from "@/components/organisms/LoginForm.vue";

@Component({ components: { LoginForm } })
export default class Signin extends Vue {
  signinSuccess(): void {
    var url = this.$route.query.redirect;
    if (url) {
      this.$router.replace(String(url));
    } else {
      this.$router.replace("/");
    }
  }
}
