










































import { Component, Emit, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { CognitoState } from "@/store/modules/cognito";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class LoginForm extends Vue {
  @Validations()
  validations = {
    username: { required },
    password: { required },
  };
  //---------------------------
  // data
  //---------------------------
  isProgressing = false;
  username = "";
  password = "";
  //---------------------------
  // mounted
  //---------------------------
  get authenticating(): boolean {
    return CognitoState.authenticating;
  }
  get authenticated(): unknown {
    return CognitoState.authenticated;
  }
  //---------------------------
  // computed
  //---------------------------
  /**
   * メールアドレスの入力エラーメッセージを返します.
   */
  get usernameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.username.$dirty) return errors;
    !this.$v.username.required &&
      errors.push("メールアドレスを入力してください.");
    return errors;
  }
  /**
   * パスワードの入力エラーメッセージを返します.
   */
  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.required && errors.push("パスワードを入力してください.");
    return errors;
  }
  //---------------------------
  // methods
  //---------------------------
  /**
   * WEBセンター管理者としてログインします.
   */
  public signin(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;

      CognitoState.authenticateUser({
        username: this.username,
        password: this.password,
      })
        .then(() => {
          //OK
          this.isProgressing = false;
          this.notifySigninSuccess();
        })
        .catch(() => {
          //NG
          this.isProgressing = false;
        });
    }
  }

  /**
   * ログイン成功
   */
  @Emit("onSigninSuccess")
  public notifySigninSuccess(): string {
    return "created";
  }
}
